import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useOktaAuth } from '@okta/okta-react';
import { postData } from '@datavant/idsb-shared/utils';
import pino from 'pino';
import OktaWidgetLoginModal from '../../components/okta/OktaWidgetModal';
import LegacyOktaLogin from '../../components/okta/LegacyOktaLogin';

const log = pino().child({ name: 'open/login' });

export default (): JSX.Element => {
    const { oktaAuth, authState } = useOktaAuth();
    const [ranHandle, setRanHandle] = useState<boolean>(false);
    const [useOktaWidget, setUseOktaWidget] = useState<boolean>(false);
    const router = useRouter();

    // Temporarily handle whether we show the okta widget or regular login button here
    // runs once on page load
    useEffect(() => {
        const fetchUseOktaWidgetFeatureFlag = async () => {
            try {
                // If we're coming from an okta widget login redirect, always use the Okta widget
                // This is to prevent a flash of the regular login button before the redirect
                if (router.asPath.includes('?fromLogin=true')) {
                    setUseOktaWidget(true);
                    return;
                }
                const flagResponse = await fetch('/api/open/use-okta-widget-flag');
                const useOktaWidget = await flagResponse.json();
                setUseOktaWidget(useOktaWidget);
            } catch {
                setUseOktaWidget(false);
            }
        };
        void fetchUseOktaWidgetFeatureFlag();
    }, []);

    // We can end up on /open/login with Okta logged in but our cookie unset.
    // Handle that in this effect
    useEffect((): void => {
        log.info({ authState, router, ranHandle }, 'Running effect');
        if (authState === null || router === undefined || ranHandle) {
            return;
        }
        if (!authState.isAuthenticated) {
            log.info('Skipping unauthenticated');
            return;
        }
        const handle = async (): Promise<void> => {
            if (authState.accessToken?.accessToken === undefined) {
                log.info('Authenticated but no tokens? Trying signOut...');
                log.info({ signOut: await oktaAuth.signOut() }, 'Reloading page after signOut');
                router.reload();
                return;
            }
            log.info('Setting token');
            await postData('/api/open/token', {
                accessToken: authState.accessToken.accessToken,
            });
            log.info('Pushing');
            void router.push('/');
        };
        log.info('Running handle');
        setRanHandle(true);
        handle().catch(error => {
            // TODO: Do something better
            console.error(error);
        });
    }, [authState, oktaAuth, ranHandle, router]);

    return useOktaWidget ? <OktaWidgetLoginModal /> : <LegacyOktaLogin />;
};
